$base-font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$body-font-family:  "Avenir Next", "IBM Plex Sans", $base-font-family;
$base-font-size:   16px ;
$content-width:    100% !default;
//$content-width:    800px !default;

// https://www.color-meanings.com/shades-of-purple-color-names-html-hex-rgb-codes/
$link-visited-color: #DA70D6;
$link-visited-color: #BA55D3;

$link-visited-color: darken(#BA55D3, 20%);
$link-visited-color: lighten(#6F456E, 20%);


@import "minima/skins/dark.scss";
@import "minima/initialize";

// Workaround Disqus dark mode issue
// https://stackoverflow.com/questions/65260505/disqus-iframe-transparency-wont-work-on-chrome-87

:root {
  color-scheme: light dark;
}
iframe {
  color-scheme: light;
}


pre, code {
	font-size: 15px;
}

.site-header {
  border-top: 0px;
}

body {
  font-family: $body-font-family;  
}

h1, h2, h3, h4, h5, h6{
  font-family: $base-font-family;
}

.wrapper {
//  margin-right: 10px;
  margin-left: 10px;
  
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h2, h3, h4, h5, h6{
  margin-bottom: $spacing-unit / 4;
}

.site-title {
  @include relative-font-size(2.5);
  //font-weight: 400;
  letter-spacing: 0px;
  font-family: $base-font-family; 
}

.post-title {
  letter-spacing: 0px;
//  font-weight: bolder;
  line-height: 1.06
}

blockquote {
	font-size: 18px;
}

.post-content {
   margin-bottom: $spacing-unit/4;

   h1, h2, h3 { margin-top: $spacing-unit /4}
   h4, h5, h6 { margin-top: $spacing-unit /4}
}

.footer-col-wrapper{
   margin-bottom: 0;

   h1, h2, h3, h4, h5, h6 { margin-top: 0}
   h1, h2, h3, h4, h5, h6 { margin-bottom: 0}
}
.site-nav{
    @include relative-font-size(1.625);
    font-family: $base-font-family; 
    
}

.post-list-heading {
    @include relative-font-size(2.625);
//    font-weight: bolder;

}


/* Solarized Dark 
For use with Jekyll and Pygments
http://ethanschoonover.com/solarized
SOLARIZED HEX      ROLE
--------- -------- ------------------------------------------
base03    #002b36  background
base01    #586e75  comments / secondary content
base1     #93a1a1  body text / default code / primary content
orange    #cb4b16  constants
red       #dc322f  regex, special keywords
blue      #268bd2  reserved keywords
cyan      #2aa198  strings, numbers
green     #859900  operators, other keywords
*/

.highlight { background-color: #002b36; color: #93a1a1 }
.highlight .c { color: #586e75 } /* Comment */
.highlight .err { color: #93a1a1 } /* Error */
.highlight .g { color: #93a1a1 } /* Generic */
.highlight .k { color: #859900 } /* Keyword */
.highlight .l { color: #93a1a1 } /* Literal */
.highlight .n { color: #93a1a1 } /* Name */
.highlight .o { color: #859900 } /* Operator */
.highlight .x { color: #cb4b16 } /* Other */
.highlight .p { color: #93a1a1 } /* Punctuation */
.highlight .cm { color: #586e75 } /* Comment.Multiline */
.highlight .cp { color: #859900 } /* Comment.Preproc */
.highlight .c1 { color: #586e75 } /* Comment.Single */
.highlight .cs { color: #859900 } /* Comment.Special */
.highlight .gd { color: #2aa198 } /* Generic.Deleted */
.highlight .ge { color: #93a1a1; font-style: italic } /* Generic.Emph */
.highlight .gr { color: #dc322f } /* Generic.Error */
.highlight .gh { color: #cb4b16 } /* Generic.Heading */
.highlight .gi { color: #859900 } /* Generic.Inserted */
.highlight .go { color: #93a1a1 } /* Generic.Output */
.highlight .gp { color: #93a1a1 } /* Generic.Prompt */
.highlight .gs { color: #93a1a1; font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #cb4b16 } /* Generic.Subheading */
.highlight .gt { color: #93a1a1 } /* Generic.Traceback */
.highlight .kc { color: #cb4b16 } /* Keyword.Constant */
.highlight .kd { color: #268bd2 } /* Keyword.Declaration */
.highlight .kn { color: #859900 } /* Keyword.Namespace */
.highlight .kp { color: #859900 } /* Keyword.Pseudo */
.highlight .kr { color: #268bd2 } /* Keyword.Reserved */
.highlight .kt { color: #dc322f } /* Keyword.Type */
.highlight .ld { color: #93a1a1 } /* Literal.Date */
.highlight .m { color: #2aa198 } /* Literal.Number */
.highlight .s { color: #2aa198 } /* Literal.String */
.highlight .na { color: #93a1a1 } /* Name.Attribute */
.highlight .nb { color: #B58900 } /* Name.Builtin */
.highlight .nc { color: #268bd2 } /* Name.Class */
.highlight .no { color: #cb4b16 } /* Name.Constant */
.highlight .nd { color: #268bd2 } /* Name.Decorator */
.highlight .ni { color: #cb4b16 } /* Name.Entity */
.highlight .ne { color: #cb4b16 } /* Name.Exception */
.highlight .nf { color: #268bd2 } /* Name.Function */
.highlight .nl { color: #93a1a1 } /* Name.Label */
.highlight .nn { color: #93a1a1 } /* Name.Namespace */
.highlight .nx { color: #93a1a1 } /* Name.Other */
.highlight .py { color: #93a1a1 } /* Name.Property */
.highlight .nt { color: #268bd2 } /* Name.Tag */
.highlight .nv { color: #268bd2 } /* Name.Variable */
.highlight .ow { color: #859900 } /* Operator.Word */
.highlight .w { color: #93a1a1 } /* Text.Whitespace */
.highlight .mf { color: #2aa198 } /* Literal.Number.Float */
.highlight .mh { color: #2aa198 } /* Literal.Number.Hex */
.highlight .mi { color: #2aa198 } /* Literal.Number.Integer */
.highlight .mo { color: #2aa198 } /* Literal.Number.Oct */
.highlight .sb { color: #586e75 } /* Literal.String.Backtick */
.highlight .sc { color: #2aa198 } /* Literal.String.Char */
.highlight .sd { color: #93a1a1 } /* Literal.String.Doc */
.highlight .s2 { color: #2aa198 } /* Literal.String.Double */
.highlight .se { color: #cb4b16 } /* Literal.String.Escape */
.highlight .sh { color: #93a1a1 } /* Literal.String.Heredoc */
.highlight .si { color: #2aa198 } /* Literal.String.Interpol */
.highlight .sx { color: #2aa198 } /* Literal.String.Other */
.highlight .sr { color: #dc322f } /* Literal.String.Regex */
.highlight .s1 { color: #2aa198 } /* Literal.String.Single */
.highlight .ss { color: #2aa198 } /* Literal.String.Symbol */
.highlight .bp { color: #268bd2 } /* Name.Builtin.Pseudo */
.highlight .vc { color: #268bd2 } /* Name.Variable.Class */
.highlight .vg { color: #268bd2 } /* Name.Variable.Global */
.highlight .vi { color: #268bd2 } /* Name.Variable.Instance */
.highlight .il { color: #2aa198 } /* Literal.Number.Integer.Long */
